import { Directive, effect, ElementRef, inject, input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { skipNullish } from '@icp/angular/rxjs-operators';
import { TranslocoService } from '@jsverse/transloco';
import { distinctUntilChanged, map, startWith } from 'rxjs';

@Directive({
    selector: '[icpErrorLabel]',
    standalone: true,
})
export class IcpErrorLabelDirective {
    icpErrorLabel = input.required<AbstractControl>();

    private transloco = inject(TranslocoService);
    private elementRef = inject(ElementRef<HTMLElement>);

    constructor() {
        effect((onCleanup) => {
            const icpErrorLabel = this.icpErrorLabel();
            const subscription = icpErrorLabel.statusChanges
                .pipe(
                    startWith(icpErrorLabel.status),
                    map((status) => {
                        if (status === 'INVALID') {
                            if (icpErrorLabel.hasError('required')) {
                                return this.transloco.translate('THIS_FIELD_IS_REQUIRED');
                            } else if (icpErrorLabel.hasError('email')) {
                                return this.transloco.translate('INVALID_EMAIL_ADDRESS');
                            } else if (icpErrorLabel.hasError('vat')) {
                                return this.transloco.translate('ERROR_NOT_A_VALID_VAT_NUMBER');
                            } else if (icpErrorLabel.hasError('url')) {
                                return `${this.transloco.translate('THIS_FIELD_IS_INVALID')} ${this.transloco.translate('HINT_CORRECT_WEBSITE_URL')}`;
                            } else {
                                return this.transloco.translate('THIS_FIELD_IS_INVALID');
                            }
                        }
                        return null;
                    }),
                    distinctUntilChanged(),
                    skipNullish,
                )
                .subscribe((errorLabel) => (this.elementRef.nativeElement.innerText = errorLabel));
            onCleanup(() => subscription.unsubscribe());
        });
    }
}
